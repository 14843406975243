const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://n8jp031c60.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://9kq8t35yg7.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.staging.debmarine.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://2m8olo71sa.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;